import React from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion"

import FeatureImg1 from "../assets/images/features-details-image/feature1.png"
import FeatureImg2 from "../assets/images/features-details-image/feature2.png"

const Software = () => (
  <Layout>
    <SEO title="Full Stack Software Development" />

    <Navbar />

    <div className="features-details-area ptb-80">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 features-details-image">
            <img
              src={FeatureImg1}
              className="animate__animated animate__fadeInUp"
              alt="image"
            />
          </div>

          <div className="col-lg-6 features-details">
            <div className="features-details-desc">
              <h3>Full Stack Software Development</h3>
              <p>
                Our development team is trained in today's most relevant
                technology stacks and can meet the following software needs.
              </p>
              <p>
                For example, we helped develop{" "}
                <a href="https://primer.net/">Primer</a>, a comprehensive
                AI-enabled platform for education and productivity.
              </p>

              <ul className="features-details-list">
                <li>Dynamic Web Based Applications</li>
                <li>Native Mobile Applications on iOS and Android</li>
                <li>Augmented and Virtual Reality Applications</li>
                <li>
                  Development of Integrations for Existing Tools, such as, Jira,
                  Salesforce, and Chrome.{" "}
                </li>
                <li>Static Landing Pages and Webpages for your Business</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="separate"></div>

        <div className="row align-items-center">
          <div className="col-lg-6 features-details">
            <div className="features-details-desc">
              <p>
                Our software development team works seamlessly with Faraya's
                designers, researchers, and data scientists to ensure that your
                solution is intuitive, aesthetically pleasing,
                research-informed, and data driven.{" "}
              </p>

              <div className="features-details-accordion">
                <Accordion allowZeroExpanded preExpanded={["a"]}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>What Technology Stack Do You Use?</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Our team is trained in many technologies, however we
                        prefer to develop on a modern technology stack that
                        minimizes the complexity and size of the source code for
                        our clients. Our preferred stack is React and
                        React-Native for frontend web and mobile development, as
                        well as Node.JS/Express for backend development.{" "}
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="b">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>Can you Implement in our Preferred Stack?</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, our team is trained in common frameworks, such as
                        Vue, Angular, PHP, Ruby, Django, Flask, and more. If you
                        have a preference of which technology stack you want
                        your application built on, in most cases, we will be
                        able to support that request.{" "}
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="c">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>How Will The Project Be Managed?</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        All of our projects are managed with the Agile process
                        of Scrum. This entails two-week sprints of development,
                        each yielding a potentially shippable Minimal Viable
                        Product (MVP). At the end of each sprint our team will
                        do a retrospective and review progress with the client
                        in order to adapt to changes as needed.{" "}
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>

          <div className="col-lg-6 features-details-image">
            <img
              src={FeatureImg2}
              className="animate__animated animate__fadeInUp"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default Software
